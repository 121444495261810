import React, { useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Slider from "rc-slider";
import 'rc-slider/assets/index.css';

const ExRangSlider = () => {
    document.title = "Rang Slider | Symox - React Admin & Dashboard Template";

    const [value, setValue] = useState(50);

    const handleSliderChange = (newValue) => {
        setValue(newValue);
    };

    const [range, setRange] = useState([30, 90]);

    const handleSliderChange1 = (newRange) => {
        // Limit the range between 30 and 90
        setRange([Math.max(newRange[0], 30), Math.min(newRange[1], 90)]);
    };

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid={true}>

                    <Breadcrumbs title="Extended" breadcrumbItem="Rang Slider" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="card-header justify-content-between d-flex align-items-center">
                                    <h4 className="card-title mb-0">Examples</h4>
                                </div>
                                <CardBody>
                                    <div>
                                        <div className="mb-5">
                                            <Row className="align-items-center">
                                                <Col lg={3}>
                                                    <h5 className="font-size-14">Basic Example</h5>
                                                </Col>
                                                <Col lg={9}>
                                                    <div id="slider">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            value={value}
                                                            onChange={handleSliderChange}
                                                            trackStyle={{ backgroundColor: "#838de7", height: 5 }}
                                                            handleStyle={{
                                                                borderColor: "#838de7",
                                                                height: 20,
                                                                width: 20,
                                                                marginTop: -8,
                                                                backgroundColor: "#838de7",
                                                            }}
                                                            railStyle={{ backgroundColor: "#d7dada", height: 5 }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mb-5">
                                            <Row>
                                                <Col lg={3}>
                                                    <h5 className="font-size-14">Using HTML5 Input Elements</h5>
                                                </Col>
                                                <Col lg={9}>
                                                    <div id="html5">
                                                        <Slider
                                                            // range={range}
                                                            min={100}
                                                            max={200}
                                                            value={range}
                                                            onChange={handleSliderChange1}
                                                            trackStyle={[{ backgroundColor: "#838de7", height: 5 }]}
                                                            handleStyle={[
                                                                {
                                                                    borderColor: "#838de7",
                                                                    height: 20,
                                                                    width: 20,
                                                                    marginTop: -8,
                                                                    backgroundColor: "#838de7",
                                                                },
                                                                {
                                                                    borderColor: "#838de7",
                                                                    height: 20,
                                                                    width: 20,
                                                                    marginTop: -8,
                                                                    backgroundColor: "#838de7",
                                                                },
                                                            ]}
                                                            railStyle={{ backgroundColor: "#d7dada", height: 5 }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mb-5">
                                            <Row>
                                                <Col lg={3}>
                                                    <h5 className="font-size-14">Non linear slider</h5>
                                                </Col>
                                                <Col lg={9}>
                                                    <div id="nonlinear">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            value={[30, 70]}
                                                            range={[30, 70]}
                                                            onChange={handleSliderChange}
                                                            trackStyle={{ backgroundColor: "#838de7", height: 5 }}
                                                            handleStyle={{
                                                                borderColor: "#838de7",
                                                                height: 20,
                                                                width: 20,
                                                                marginTop: -8,
                                                                backgroundColor: "#838de7",
                                                            }}
                                                            railStyle={{ backgroundColor: "#d7dada", height: 5 }}
                                                        />
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mb-5">
                                            <Row>
                                                <Col lg={3}>
                                                    <h5 className="font-size-14">Only showing tooltips when sliding handle</h5>
                                                </Col>
                                                <Col lg={9}>
                                                    <div className="slider" id="slider-hide">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            value={[20, 60]}
                                                            range={[20, 60]}
                                                            onChange={handleSliderChange}
                                                            trackStyle={{ backgroundColor: "#838de7", height: 5 }}
                                                            handleStyle={{
                                                                borderColor: "#838de7",
                                                                height: 20,
                                                                width: 20,
                                                                marginTop: -8,
                                                                backgroundColor: "#838de7",
                                                            }}
                                                            railStyle={{ backgroundColor: "#d7dada", height: 5 }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mb-5">
                                            <Row>
                                                <Col lg={3}>
                                                    <h5 className="font-size-14">Soft limits</h5>
                                                </Col>
                                                <Col lg={9}>
                                                    <div id="soft">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            value={10}
                                                            onChange={handleSliderChange}
                                                            trackStyle={{ backgroundColor: "#838de7", height: 5 }}
                                                            handleStyle={{
                                                                borderColor: "#838de7",
                                                                height: 20,
                                                                width: 20,
                                                                marginTop: -8,
                                                                backgroundColor: "#838de7",
                                                            }}
                                                            railStyle={{ backgroundColor: "#d7dada", height: 5 }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="mb-5" style={{ height: "150px" }}>
                                            <Row>
                                                <Col lg={3}>
                                                    <h5 className="font-size-14">Creating a toggle</h5>
                                                </Col>
                                                <Col lg={9}>
                                                    <div id="slider-toggle">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            marks={{ 10: "10%", 20: "20%", 30: "30%", 40: "40%", 50: "50%", 60: "60%", 70: "70%", 80: "80%", 90: "90%", 100: "100%" }}
                                                            value={[20, 60]}
                                                            range={[20, 60]}
                                                            onChange={handleSliderChange}
                                                            trackStyle={{ backgroundColor: "#838de7", height: 5 }}
                                                            handleStyle={{
                                                                borderColor: "#838de7",
                                                                height: 20,
                                                                width: 20,
                                                                marginTop: -8,
                                                                backgroundColor: "#838de7",
                                                            }}
                                                            railStyle={{ backgroundColor: "#d7dada", height: 5 }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ExRangSlider;